<template>
	<div class="convention-hall page">
		<van-nav-bar class="nav-bar" title="CHỌN TỈNH" />
		<div class="convention-item">
			<van-tabs animated sticky line-width="100px" :swipeable="true">
				<van-tab title="Thành phố">
					<div class="card">Dịch vụ cung cấp gái đẹp miễn phí  trên toàn quốc , Có thể sắp xếp bất cứ nơi nào tại Việt Nam và bất cứ lúc nào  , Bạn chỉ cần liên hệ lễ tân sẽ lập tức sắp xếp。</div>
					<div class="address">
                        <van-cell-group v-for="(val, key) in addlist" :key="key">
						<van-cell span="" offset="" @click="addgo(v)" v-for="(v, k) in val" :key="k">{{v.name}}</van-cell>
						</van-cell-group>
						
						
						
						
					</div>
				</van-tab>
				<van-tab title="Giới Thiệu Nền Tảng">
					<div class="card">Dịch vụ cung cấp gái đẹp miễn phí  trên toàn quốc , Có thể sắp xếp bất cứ nơi nào tại Việt Nam và bất cứ lúc nào  , Bạn chỉ cần liên hệ lễ tân sẽ lập tức sắp xếp。</div>
					<div class="rig-box">
						<p class="rig-title">Có những mẫu em gái nào?</p>
						<p class="rig-content">Người nổi tiếng trên mạng, người mẫu, tiếp viên hàng không, người mẫu trẻ, sinh viên đại học, chỉ có điều bạn không nghĩ ra được và bạn không thể làm được nếu không có nền tảng này</p>
						<p class="rig-title">Khu vực phục vụ?</p>
						<p class="rig-content">Các cuộc hẹn miễn phí trong cùng một thành phố, trên không ở bất kỳ đâu trong cả nước, các thành phố cấp một và cấp hai tại địa phương ở Việt Nam và các thành phố cấp ba cũng có thể được sắp xếp bằng cách liên hệ với nhân viên tiếp tân。</p>
						<p class="rig-content">Dịch vụ cung cấp gái đẹp miễn phí  trên toàn quốc , Có thể sắp xếp bất cứ nơi nào tại Việt Nam và bất cứ lúc nào  , Bạn chỉ cần liên hệ lễ tân sẽ lập tức sắp xếp.</p>
					</div>
				</van-tab>
			</van-tabs>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			addlist: [
				{

				},
				{

				}
			]
		};
	},
	methods: {
		addgo(data) {
			if (!localStorage.getItem('token')) {
				this.$router.push({ path: '/Login' });
			} else {
				this.$router.push({ path: '/list?id=' + data.id + '&name=' + data.name });
			}
		},
		getAddress() {
			this.$http({
				method: 'get',
				url: 'address_list'
			}).then(res => {
				this.addlist = res.data;
			});
		}
	},
	created() {
		this.getAddress();
	}
};
</script>

<style lang="less" scoped>
.page {
	position: absolute !important;
	top: 0;
	left: 0;
	right: 0;
	background-color: #f2f2f5;
}
.nav-bar {
	background: linear-gradient(90deg, #775fd9, #c24491);
	height: 100px;
}
.van-nav-bar {
	line-height: 50px;
}
::v-deep .van-nav-bar__title {
	max-width: 60%;
	margin: 0 auto;
	color: #ffffff;
	font-size: 35px;
}
::v-deep .van-nav-bar__content {
	height: 100px;
}
.van-sidebar {
	width: 180px;
}
// /deep/ .van-col{
	// padding: 30px 0px;
// }
.convention-hall {
	display: flex;
	flex-direction: column;
	bottom: 20px;
	background: #f2f2f5;
}
::v-deep .van-tab {
	font-size: 30px;
	line-height: 100px;
	font-weight: bold;
}
::v-deep .van-tabs__line {
	background-color: #775fd9;
}
::v-deep .van-tabs--line .van-tabs__wrap {
	height: 100px;
}
::v-deep .van-tabs__wrap--scrollable .van-tab {
	padding: 0 23px;
}
.card {
	background-color: #8a637d;
	padding: 0.625rem;
	width: 95%;
	color: white;
	font-size: 0.8125rem;
	margin: 0.625rem auto;
	border-radius: 0.375rem;
}
::v-deep .van-row--flex {
	height: 80px;
	line-height: 80px;
}

/deep/ .van-cell{
	padding: 30px 22px;
	font-size: 30px;
	line-height:30px;
}
.rig-box {
	width: 95%;
	margin: 0.625rem auto;
}
.rig-title {
	color: #0bdab0;
	font-size: 1.125rem;
}
.rig-content {
	font-size: 20px;
	// margin-top: 10px;
}
.address {
	width: 94%;
	margin: 0 auto;
	margin-bottom: 200px;
}
</style>