<template>
  <div class="container page">
    <van-nav-bar title="Liên kết số tài khoản ngân hàng" class="nav-bar">
      <template #left>
        <van-icon name="arrow-left" color="#fff" @click="back()"/>
      </template>
    </van-nav-bar>
    <div class="main-box">
      <div class="label">Vui lòng điền số tài khoản của bạn để rút tiền</div>
      <van-cell-group>
        <van-field v-model="bankid" label="Số tài khoản" type="digit" placeholder="Vui lòng nhập số thẻ ngân hàng thật" />
        <van-field v-model="bank" label="Tên ngân hàng" readonly placeholder="Vui lòng chọn ngân hàng" @click="showSelectBanks()"/>
      </van-cell-group>
      <p>Khách hàng thân ái,để bảo vệ sự an toàn cho tài sản của bạn,Vui lòng liên kết tên thật và cài đặt mật khẩu rút tiền,Nếu tên không khớp với tên tài khoản,sẽ không thể rút tiền.</p>
    </div>
    <van-button class="bindCard" type="default" @click="bindCard()">Xác nhận</van-button>
    <van-popup v-model="showBank" round position="bottom" :style="{ height: '35%' }" >
      <van-picker
          show-toolbar
          :columns="banks"
          @confirm="onConfirm"
          @cancel="onCancel"
      />
    </van-popup>
  </div>
</template>

<script>
export default {
  data() {
    return {
      banks: [],
      showBank:false,
      userInfo:{},
      bankid:"",
      bank:""
    };
  },
  methods: {
    back(){
      return window.history.back();
    },
    bindCard(){
      if(this.userInfo.bankid){
        this.$toast("Vui lòng nhập số thẻ ngân hàng！");
        return true;
      }
      if(this.bank === "" || this.bank === null || this.bank === undefined){
        this.$toast.fail("Vui lòng chọn ngân hàng！");
        return false;
      }
      this.$http({
        method: 'post',
        data:{bankid:this.bankid,bank:this.bank},
        url: 'user_set_bank'
      }).then(res=>{
        if(res.code === 200){
          this.$router.push({path:'/Mine'})
          this.$toast(res.msg);
        }else if(res.code ===401){
          this.$toast(res.msg);
        }
      })
    },
    showSelectBanks(){
      this.showBank = true;
    },
    getUserInfo(){
      this.$http({
        method: 'get',
        url: 'user_info'
      }).then(res=>{
        if(res.code === 200){
          this.userInfo = res.data;
          this.name = res.data.name;
        }else if(res.code ===401){
          this.$toast(res.msg);
        }
      })
    },
    getBankList(){
      this.$http({
        method: 'get',
        url: 'sys_get_banks'
      }).then(res=>{
        if(res.code === 200){
          this.banks = res.data;
        }else if(res.code ===401){
          this.$toast(res.msg);
        }
      })
    },
    onConfirm(value) {
      this.bank = value.text
      this.showBank = false;
    },
    onCancel() {
      this.showBank = false;
    },
    getUserBankInfo(){
      this.$http({
        method: 'get',
        url: 'user_get_bank'
      }).then(res=>{
        if(res.code === 200){
          if(res.data.is_bank){
            this.is_bind = true;
          }else {
            this.is_bind = false;
          }
        }else if(res.code ===401){
          this.$toast(res.msg);
        }
      })
    }
  },
  created() {
    if(!localStorage.getItem('token')){
      this.$router.push({path:'/Login'})
    }else {
      this.getUserInfo();
      this.getBankList();
      this.getUserBankInfo();
    }
  }
};
</script>

<style lang='less' scoped>
@import "../../assets/css/base.css";
.van-cell {
  font-size: 32px;
  line-height: 80px;
}
.van-hairline--bottom::after {
  border-bottom-width: 3px;
}
.bankbox{
  padding: 15px;
  color: #000;
  background-color: #fff;
}
.bankbox .title{
  padding: 8px 10px 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: 28px;
}
.main-box{
  background: #fff;

}
.main-box .label{
  padding: 20px;
  font-size: 35px;
  color: #797878;
}
::v-deep .van-picker__toolbar {
  height: 50px;
}
::v-deep .van-picker__cancel, .van-picker__confirm {
  padding: 0 20px;
  font-size: 20px;
}
::v-deep .van-picker-column {
  font-size: 40px;
}
.main-box p{
  padding: 0 20px;
  font-size: 30px;
  color: #ee0a24;
}
.bindCard {
  margin: 20px 30px 0;
  height: 80px;
  line-height: 1.22667rem;
  border-radius: 50px;
  color: #fff;
  font-size: 30px;
  font-weight: bolder;
  border: none;
  background: linear-gradient(90deg,#c24491,#775fd9);
}
</style>
