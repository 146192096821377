<template>  

  <div class="movie-hall page">  

    <!-- 弹窗部分 -->  

    <div v-if="showModal" class="modal-overlay">  

      <div class="modal">  

        <div class="modal-header" style="background: linear-gradient(270deg, #c24491, #775fd9); color: white; padding: 10px; text-align: center;">Gợi ý ấm áp</div>  

        <div class="modal-body" style="background-color: white; padding: 20px; text-align: center;">  

          Quý khách chưa hoàn thành cập nhật thành công mã QR để trở thành hội viên VIP của Hệ Thống chúng tôi nên chỉ được dùng thử 15s. Quý khách cần liên hệ với người tư vấn để được hướng dẫn hoàn thành cập nhật mã QR và trải nghiệm tốt các dịch vụ Thiên Đường 79. Xin cảm ơn!!!  

        </div>  

      </div>  

    </div>  

  

    <van-nav-bar class="nav-bar" title="VIDEO" />  

    <van-tabs v-model="active" animated swipeable @change="OnChange">  

      <van-tab v-for="(v, key) in videolitem" :key="key" :title="v.name" :name="v.key"></van-tab>  

    </van-tabs>  

    <swiper class="video_swiper" ref="swiper" :options="videoSwiperOption" @slideChange="itemChange">  

      <swiper-slide v-for="(v, key) in videolitem" :key="key">  

        <div class="movie-list-tab">  

          <van-pull-refresh v-model="isLoading" @refresh="onRefresh">  

            <div class="hot-recommend-div">  

              <van-list  

                v-model="loading"  

                :finished="finished"  

                :immediate-check="false"  

                finished-text="Không còn nữa."  

                @load="onLoad"  

              >  

                <div class="list-item">  

                  <div  

                    class="movie-list-item"  

                    v-for="(video, index) in videolist"  

                    :key="index"  

                    @click="toPlayVideo(video.id)"  

                  >  

                    <van-image class="cover_img" round :src="video.vod_pic">  

                      <template v-slot:loading>  

                        <van-loading type="circular" />  

                      </template>  

                    </van-image>  

                    <div class="movie-list-item-bottom">  

                      <div class="movie-time-div">  

                        <span>{{ video.vod_name }}</span>  

                        <span>Chơi:{{ video.count }}</span>  

                      </div>  

                    </div>  

                  </div>  

                </div>  

              </van-list>  

            </div>  

          </van-pull-refresh>  

        </div>  

      </swiper-slide>  

    </swiper>  

  </div>  

</template>

<script>
import { Toast } from 'vant';
export default {
  data() {
    return {
      active: 0,
      isLoading: false,
      count:0,
      loading: false,
      finished: false,
      refreshing: false,
      videolitem: [],
      videolist: [],
      number:0,
      page:0,showModal: false,
      videoSwiperOption: {
        slidesPerView: 'auto',
        spaceBetween: 0,
        slidesPerGroup : 1,
      }
    };
  },
  methods: {
    getVideoClass(){
      this.$http({
        method: 'get',
        url: 'video_class'
      }).then(res=>{
        this.videolitem = res.data;
      })
    },
    toPlayVideo(id) {  
      if (!localStorage.getItem('token')) {  
        this.showModal = true; // 显示弹窗  
        setTimeout(() => {  
          this.showModal = false; // 3秒后关闭弹窗  
        }, 3000);  
      } else {  
        this.$router.push({ path: '/PlayVideo?id=' + id });  
      }  
    },
    itemChange(){
      this.active = this.$refs.swiper.swiper.activeIndex
      this.OnChange()
    },
    getVideoList(){
      this.$http({
        method: 'get',
        data:{id:this.active,page:this.page},
        url: 'video_list'
      }).then(res=>{
        this.videolist = this.videolist.concat(res.data.data);
        this.count = res.data.count;
        this.page++;

      })
    },
    onLoad() {
        this.getVideoList();
      let timer = setTimeout(() => {
        if (this.refreshing) {
          this.videolist = [];
          this.refreshing = false;
        }
        this.loading = false;
        if (this.videolist.length === this.count) {
          this.finished = true;
        }
        this.finished && clearTimeout(timer);//清除计时器
      }, 500);
    },
OnChange() {  
  this.videolist = []; // 清空视频列表  
  this.page = 1;       // 重置分页到第一页  
  this.count = 0;      // 重置总数  
  this.getVideoList(); // 重新获取视频列表  
},
    onRefresh() {
      setTimeout(() => {
        this.finished = false;
        this.loading = true;
        this.onLoad();
        this.isLoading = false;
        Toast('Update thành công');
      }, 500);
    },
  },
  created() {
    this.getVideoClass();//获取视频类目
    this.OnChange()
  }
};
</script>

<style lang='less' scoped>
.modal-overlay {    
  position: fixed;    
  top: 0;    
  left: 0;    
  right: 0;    
  bottom: 0;    
  background-color: rgba(0, 0, 0, 0.5);    
  display: flex;    
  justify-content: center;    
  align-items: center;  
  z-index: 1000; /* 增加一个足够高的 z-index 值 */  
}  
  
.modal {    
  background-color: white;    
  padding: 20px;    
  border-radius: 5px;    
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);    
  max-width: 500px;    
  width: 100%;    
  position: relative;    
  text-align: center;  
  z-index: 1001; /* 弹窗内容的 z-index 应该比遮罩层稍高 */  
}  
  
.modal-header {  
  font-size: 18px;  
  font-weight: bold;  
}
.page{
  position: absolute!important;
  top: 0;
  left: 0;
  right: 0;
  background-color: #f2f2f5;
}
.nav-bar{
  background: linear-gradient(270deg,#c24491,#775fd9);
  height: 100px;

}
.van-nav-bar {
  line-height: 50px;
}

::v-deep .van-nav-bar__title {
  max-width: 60%;
  margin: 0 auto;
  color: #ffffff;
  font-size: 35px;
}
::v-deep .van-nav-bar__content {
  height: 100px;
}

.movie-hall{
  display: flex;
  flex-direction: column;
  bottom: 100px;
  background: #f2f2f5;
}
::v-deep .van-tabs__nav {
  background: linear-gradient(270deg,#c24491,#775fd9);
}
::v-deep .van-tab {
  color: #ffffff;
  font-size: 30px;
}
::v-deep .van-tabs__line {
  bottom: 15px;
  width: 55px;
  height: 7px;
  border-radius: 0px;
  background-color: #ffffff;
}
::v-deep .van-tabs--line .van-tabs__wrap {
  height: 100px;
}
::v-deep .van-tabs__wrap--scrollable .van-tab {
  padding: 0 23px;
}
::v-deep  .van-hairline--bottom::after {
  border-bottom-width: 0px;
}
.video_swiper {
  width: 100%;
  flex: 1;
  .swiper-slide {
    flex-shrink: 0;
    flex-grow: 0;
    flex-basis: 100%;
    justify-content: center;
    height: 100%;
    position: relative;
    transition-property: transform;
  }
}
.movie-list-tab {
  overflow: auto;
  height: 100%;
}
::v-deep .van-pull-refresh__track .van-pull-refresh__head *{
  color: #000;
  font-size: 35px;
}
.movie-list-tab .hot-recommend-div{
  height: 100%;
  margin: 10px auto;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  //overflow: auto;
}
.list-item{
  display: flex;
  width: calc(100% - 50px);
  margin: 10px auto;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.list-item .movie-list-item:nth-child(odd) {
  margin-right: 20px;
}
.movie-list-item .cover_img{
  border-radius: 20px;
  width:335px;
  height:290px;
}
.movie-list-item{
  margin-bottom: -10px;
}
.list-item .movie-list-item-bottom{
  position: relative;
  width: 335px;
  bottom: 42px;
}
.list-item .movie-list-item-bottom .movie-time-div{
  background-color: rgba(0,0,0,.4);
}
.list-item .movie-list-item-bottom>div {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.list-item .movie-list-item-bottom .movie-time-div .van-count-down {
  color: #fff;
}
.list-item .movie-list-item .movie-time-div span:first-child {
  overflow: hidden;
  white-space: nowrap;
  width: 180px;
  padding-left: 8px;
  font-size: 25px;
}
.list-item .movie-time-div {
  color: #fff;
  border-radius: 0 0 20px 20px;
  height: 35px;
}
</style>
